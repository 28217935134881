import React, {useState, useEffect} from 'react'
import NoSidebarLayout from "../layout/NoSidebarLayout";
import {Link, graphql} from 'gatsby'
import styled from 'styled-components'
import ButtonCardItem from "../components/ButtonCards/ButtonCardItem";
import { CardGrid, CardWrapper } from "../components/ButtonCards/CardElements";
import {news, blogBanner} from "../images";
import SEO from '../components/seo';

const blogImg = styled.img `
  width: 100px;
  height: 100px;
`

function renderNodesWithImage(props) {
  const node = props
  return (
    <CardWrapper key={node.slug}>
      <ButtonCardItem path={node.slug} label={node.date} text={node.title} src={node.featuredImage.node.mediaItemUrl}/>
    </CardWrapper>
  )
}

function renderNodesWithoutImage(props) {
  const node = props
  return (
    <CardWrapper key={node.slug}>
      <ButtonCardItem path={node.slug} label={node.date} text={node.title} src={news}/>
    </CardWrapper>
  )
}

function InfiniteScroll(props) {
  const edges = props.edges
  const [hasMore, setMore] = useState(edges.length > 10)
  const [currentList, addToList] = useState([...edges.slice(0,10)])

  const loadEdges = () => {
    const currentLength = currentList.length
    const more = currentLength < edges.length
    const nextEdges = more ? edges.slice(currentLength, currentLength + 20) : []
    setMore(more)
    addToList([...currentList, ...nextEdges])
  }

  const handleScroll = () => {
    console.log(document.documentElement.offsetHeight)
    if (!hasMore) return ;
    if (window.innerHeight + document.documentElement.scrollTop > document.documentElement.offsetHeight - 350) {
      loadEdges()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [hasMore, currentList])

  return (
    <>
      <CardGrid>
        {currentList.map(({ node }) => {
        return (node.featuredImage) ? renderNodesWithImage(node) : renderNodesWithoutImage(node)
        })}
      </CardGrid>
    </>
  )
}

export default function blog ({data}) {
  const edges = data.allWpPost.edges
  return (
        <NoSidebarLayout pageTitle="Blog" background={blogBanner}pageDescription="Catch up on our latest news and advice">
          <SEO title="Blog | Guardianship | British Education | Homestay" description="Study Links works alongside the best schools & other educational institutions in the UK. Find out more and additional advice through our network of blog content."/>
          <InfiniteScroll edges={edges}/>
        </NoSidebarLayout>
  )
}

export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "MM-DD-YYYY")
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`